<template>
  <div>
    <b-popover
      :target="`${label_triggers}${object.id}`"
      :triggers="triggers"
      :placement="placement"
    >
      <template #title>
        <div v-if="content && content.length" class="modalities-popover-title">
          Esta
          {{
            $getVisibleNames("mesh.egressprofilematter", false, "Asignatura")
          }}
          cuenta con
          {{
            content.length && content.length > 1
              ? content.length +
                " " +
                $getVisibleNames(
                  "mesh.modality",
                  true,
                  "Modalidades de Asignaturas"
                ).toLowerCase()
              : content.length +
                " " +
                $getVisibleNames(
                  "mesh.modality",
                  false,
                  "Modalidad de Asignaturas"
                ).toLowerCase()
          }}.
        </div>
      </template>
      <template v-if="content && content.length">
        <div>
          <b-badge
            class="modality-badge"
            v-for="item in content"
            :key="item.id"
            variant="info"
          >
            {{ item.order }}.
            {{ item.name }}
          </b-badge>
        </div>
      </template>
      <template v-else>
        <div>
          {{
            $getVisibleNames("mesh.egressprofilematter", false, "Asignatura")
          }}
          sin
          {{
            $getVisibleNames(
              "mesh.modality",
              false,
              "Modalidad de Asignaturas"
            )
          }}.
        </div>
      </template>
    </b-popover>
  </div>
</template>

<script>
export default {
  name: "ModalitiesPopover",
  props: {
    object: {
      type: Object,
    },
    content: {
      type: Array,
    },
    placement: {
      type: String,
      default: "top",
    },
    triggers: {
      type: String,
      default: "hover",
    },
    label_triggers: {
      type: String,
      default: "modalities-badge-",
    },
  },
  data() {
    return {};
  },
};
</script>
<style scoped>
.popover {
  max-width: 320px;
}
.modalities-popover-title {
  font-size: 0.875rem;
}
.modality-badge {
  margin-right: 0.2rem;
}
</style>